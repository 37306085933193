<template>
 <v-container>
    <v-row justify="center">
      
      <v-col cols="auto">
        
        <v-btn @click="toggleTimer" size="x-large">{{ isRunning ? 'STOP' : 'START' }}</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="resetTimer" size="x-large">RESET</v-btn>
      </v-col>
      
    </v-row>
    <v-row justify="center">
      
      <v-col cols="auto">
        <v-btn @click="startListening" size="x-large">MICRO ON</v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn @click="stopListening" size="x-large">MICRO OFF</v-btn>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col cols="12">{{ formattedTime }}</v-col>
    </v-row>
  <div>
    
    <p>Commande capturée : {{ transcript }}</p>
    <br>
    <p>Score EJF : {{ goalsEJF }}</p>
    <p>Score Aywaille : {{ goalsAdversaire }}</p>
    <br><br>
    <h3>Joueurs :</h3>
    <ul>
      <li v-for="joueur in joueurs" :key="joueur.numero">
        Joueur {{ joueur.numero + "." + joueur.nom}}:
        <ul>
          <li v-for="(action, index) in joueur.actions" :key="index">
            Action - {{ action.type }}, {{ action.actionType }}, Minute - {{ action.minute }}
          </li>
          
        </ul>
      </li>
    </ul>
  </div>
</v-container>
</template>

<script setup>
import { ref,unref,computed, onUnmounted } from 'vue';

// Variables pour suivre les scores des équipes
const goalsEJF = ref(0);
const goalsAdversaire = ref(0);

// Liste des mots-clés pour le type de frappe
const keywords = ['tête', 'pied gauche', 'pied droit', 'penalty', 'coup franc'];
const joueurs = [
  {
    numero: 1,
    nom: "Hostiez Bastian (G)",
    actions:[]
  },
  {
    numero: 2,
    nom: "Charaf Ayoub",
    actions:[]
  },
  {
    numero: 3,
    nom: "Dabre Ishak",
    actions:[]
  },
  {
    numero: 4,
    nom: "Jeanpierre Milo (C)",
    actions:[]
  },
  {
    numero: 5,
    nom: "Bathily Sayydina",
    actions:[]
  },
  {
    numero: 6,
    nom: "Schirmenti Gabriel",
    actions:[]
  },
  {
    numero: 7,
    nom: "Ouattara Bakary",
    actions:[]
  },
  {
    numero: 8,
    nom: "Di Rutigliano Lenny",
    actions:[]
  },
  {
    numero: 9,
    nom: "Godin Axel",
    actions:[]
  },
  {
    numero: 10,
    nom: "Nizette Hugo",
    actions:[]
  },
  {
    numero: 11,
    nom: "Venken Diego",
    actions:[]
  },
  {
    numero: 12,
    nom: "Toussaint Gilles",
    actions:[]
  },
  {
    numero: 14,
    nom: "Perugini Mathis",
    actions:[]
  },
  {
    numero: 15,
    nom: "Jungheim Soni",
    actions:[]
  },
  {
    numero: 16,
    nom: "Raway Loris",
    actions:[]
  },
  
    
]

// Fonction pour trouver un joueur par son numéro
const findPlayerByNumber = (numero) => {
  return joueurs.find(joueur => joueur.numero === numero);
};

const transcript = ref('');
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();
const getCurrentTime = () => new Date().toLocaleTimeString();

recognition.continuous = true;
recognition.interimResults = false; // Afficher les résultats finaux seulement
recognition.lang = 'fr-FR';

const parseCommand = (Command) => {
  const lowerCommand = Command.toLowerCase();

   // Vérifier si la commande contient "but EJF" et le numéro du joueur
   if (/(but annulé|goal annulé|but annuler|goal annuler).(ejf|fléron)/i.test(lowerCommand)) {
    goalsEJF.value--;
    return;
  }
  
   const match = lowerCommand.match(/but.*ejf.*numéro (\d+)/i);
   if (match) {
    const playerNumber = parseInt(match[1]);
    const player = findPlayerByNumber(playerNumber);
    if (player) {
      // Incrémenter le compteur de buts EJF
      goalsEJF.value++;

      const keywordMatch = keywords.find(keyword => lowerCommand.includes(keyword));
      const actionType = keywordMatch || 'non spécifié'; // Utilise 'non spécifié' si aucun mot-clé n'est trouvé

      // Ajouter l'action "goal" pour le joueur
      player.actions.push({
        type: 'goal',
        minute: unref(formattedTime),
        actionType: actionType
      });

      console.log(`But pour EJF, Joueur ${playerNumber} à ${player.actions[player.actions.length - 1].minute}`);
    } else {
      console.log(`Joueur avec le numéro ${playerNumber} non trouvé.`);
    }

    return
  }

  const match2 = lowerCommand.match(/but*/i);
   if (match2) {
      // Incrémenter le compteur de buts EJF
      goalsAdversaire.value++;

      return  
   }
    
  
  
  /*
  if (/(but annulé|goal annulé|but annuler|goal annuler).(ejf|fléron)/i.test(lowerCommand)) {
    goalsEJF.value--;
    return;
  }
  
  if (/(but|goal).*(ejf|fléron)/i.test(lowerCommand)) {
    goalsEJF.value++;
    console.log('Goal pour l\'EJF ou Fléron détecté !');
  } else if (/but.*|goal.*i.test(lowerCommand)) {
    goalsAdversaire.value++;
    console.log('Goal pour l\'adversaire détecté !');
  }
  */
}

recognition.onresult = (event) => {
  const lastResult = event.results[event.resultIndex];
  transcript.value = lastResult[0].transcript;
  // Traitement de la commande ici, ex: extraire les infos "Goal pour Equipe1..."
  parseCommand(transcript.value)
};

const startListening = () => {
  recognition.start();
};

const stopListening = () => {
  recognition.stop();
};


const isRunning = ref(false);
const elapsedTime = ref(0);
let timerInterval = null;

const toggleTimer = () => {
  isRunning.value = !isRunning.value;

  if (isRunning.value) {
    timerInterval = setInterval(() => {
      elapsedTime.value += 1;

      if (elapsedTime.value >= 120 * 60) {
        resetTimer();
      }
    }, 1000);
  } else {
    clearInterval(timerInterval);
  }
};

const resetTimer = () => {
  clearInterval(timerInterval);
  isRunning.value = false;
  elapsedTime.value = 0;
};

const formattedTime = computed(() => {
  const minutes = Math.floor(elapsedTime.value / 60).toString().padStart(2, '0');
  const seconds = (elapsedTime.value % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`;
});

// Cleanup interval on component unmount
onUnmounted(() => {
  clearInterval(timerInterval);
});

</script>
