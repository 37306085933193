<template>
  
  <v-app>
    <HeaderBar/>
    <router-view/>
    <Footer/>
  </v-app> 

  
</template>

<script setup>
import { onMounted } from 'vue';
import { useFootStore }  from '@/stores/foot'
import { getClubs } from '@/utils/footballApi';
import HeaderBar from './components/HeaderBar.vue';
import Footer from './components/Footer.vue';

const foot = useFootStore()

onMounted(async ()=>{
  console.log('on mounted: ', process.env.NODE_ENV,': ', foot.version)
 // const data = await getClubs()
 // console.log(data)
 

})


</script>

<style scoped>

</style>
