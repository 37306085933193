<template>
   
</template>

<script setup>
    import { ref } from 'vue';

    const drawer = ref(false)

</script>

<style scoped>
</style>