import { defineStore } from "pinia";

import { ref,computed } from "vue";

 

export const useFootStore = defineStore("footStore",() => {
    const version = ref('1.0.3')

    return {
        version
    }
})